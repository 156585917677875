@import '../../../css/global';

.wrap {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 888;
  // background: rgba(255, 0, 0, 0.1);

  @include electron-no-drag;

  &Inline {
    position: relative;
    top: 0;
    left: 0;
    right: auto;
    z-index: auto;
  }

  &WithoutLibrary {
    :global(html.electron.electron-platform-win) & {
      left: 20px;
      right: auto;
    }
  }

  &WithQueue {
    right: 300px;

    @media (min-width: 1024px) {
      right: 340px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 887;

  .wrapInline & {
    z-index: auto;
  }
}

//
//
//

.status {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 6px;
  margin: 0 0 0 auto;
  background: var(--color-panel-background);
  border: 1px solid var(--color-border);
  border-radius: 9px;
  box-shadow: 0 0 10px var(--color-shadow);

  :global(.layout-content.cq-800) & {
    padding: 6px 6px 6px 15px;
  }

  @include focus-self-outline;

  &:hover,
  &:focus {
    background: var(--color-hover);
  }

  .wrapInline & {
    flex-direction: row-reverse;
    width: 100%;
    background: var(--color-border);
    border: 1px solid var(--color-opacity-005);
    border-radius: 6px;
    box-shadow: none;
  }

  .wrapWithoutLibrary & {
    :global(html.electron.electron-platform-win) & {
      justify-content: flex-start;
      margin: 0 auto 0 0;
    }
  }
}

.thumb {
  overflow: hidden;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  background: var(--color-opacity-01);
  border-radius: 4px;

  :global(.layout-content.cq-800) & {
    margin-left: 12px;
  }

  .wrapInline & {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.thumbIcon {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 28px;
  height: 28px;
  opacity: var(--opacity-02);
}

.content {
  max-width: 160px;
  font-weight: 500;
  text-align: right;

  :global(.layout-content) & {
    display: none;
  }
  :global(.layout-content.cq-800) & {
    display: block;
  }

  .wrapInline & {
    flex: 0 0 auto;
    width: calc(100% - 48px);
    text-align: left;
  }
}

.library {
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.server {
  overflow: hidden;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-opacity-05);
}

//
//
//

.menu {
  overflow-y: auto;
  min-width: 100%;
  max-height: calc(100vh - 95px);
  max-height: calc(100svh - 95px);
  margin: 5px 0 0 auto;
  background: var(--color-panel-background);
  border-radius: 9px;
  box-shadow: 0 0 10px var(--color-shadow);
  font-size: 12px;
  text-align: left;

  .wrapInline & {
    max-height: none;
    margin: 3px 0 0 auto;
    border-radius: 6px;
    box-shadow: none;
  }
}

.button {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 10px 80px 10px 15px;
  background: var(--color-panel-background);
  border: 1px solid var(--color-border);
  text-align: left;

  @include focus-self-fill;

  &:first-child {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    .wrapInline & {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
  &:last-child {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;

    .wrapInline & {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &.buttonLibrary {
    padding-left: 30px;
  }

  &:hover,
  &:focus {
    background: var(--color-hover);
  }

  .wrapInline & {
    padding-right: 36px;
    background: var(--color-opacity-0025);
    border-color: var(--color-opacity-01);

    &:hover,
    &:focus {
      background: var(--color-hover);
    }
  }
}

.button + .button {
  border-top: none;
}

.iconBefore {
  display: block;
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  opacity: var(--opacity-05);
}

.iconAfter {
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  width: 14px;
  height: 14px;
  opacity: var(--opacity-05);
  transform: translateY(-50%);
}

.iconArrow {
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  height: 10px;
  opacity: var(--opacity-05);
  transform: translateY(-50%);
}

.iconCurrent {
  opacity: 1;
  color: var(--color-primary);
}

.iconHover {
  display: none;

  .button:hover &,
  .button:focus & {
    display: block;
  }
}
