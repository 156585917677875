@import '../../../css/global';

.nav {
  display: flex;
  position: sticky;
  overflow-x: hidden;
  justify-content: flex-end;
  align-items: flex-end;
  top: 0;
  height: 54px;
  padding: 4px 0 0;
  background: var(--color-panel-background);
  border-right: 1px solid var(--color-border);
  z-index: 2;

  @include electron-no-drag;
}

.prev,
.next {
  position: relative;
  right: -4px;
  width: 40px;
  height: 100%;
  padding: 17px 7px 17px 17px;
  border-radius: 6px;

  @include focus-self-outline;

  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
}
.next {
  padding: 17px 17px 17px 7px;
}

//
// WRAP
//

.wrap {
  overflow-x: hidden;
  min-height: 100%;
  min-height: calc(100% - 54px);
  padding: 5px 12px 20px;
  background: var(--color-panel-background);
  border-right: 1px solid var(--color-border);
}

//
// USER MENU
//

.userMenu {
}

//
// SEARCH
//

.search {
  // below user menu
  .userMenu + & {
    margin-top: 10px;
  }
}

.searchInput {
  input {
    display: block;
    width: 100%;
    padding: 7px 35px 7px 35px;
    margin: 0 0 1px;
    background: var(--color-border);
    border: 1px solid var(--color-opacity-005);
    border-radius: 6px;
    font-size: 13px;
    color: var(--color-text);

    @include focus-self-outline;

    &::placeholder {
      color: var(--color-opacity-04);
    }

    &:focus {
      border-color: var(--color-opacity-03);
    }
  }

  &:focus-within {
    input {
      border-color: var(--color-opacity-03);
    }
  }
}

.searchIcon {
  position: absolute;
  top: 10px;
  left: 13px;
  width: 14px;
  height: 14px;
  opacity: var(--opacity-05);
  pointer-events: none;
}

.crossIcon {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 22px;
  height: 26px;
  padding: 6px 7px 6px 1px;
  background: transparent;
  border-radius: 2px;

  @include focus-self-outline;

  span {
    display: block;
    background: var(--color-opacity-025);
    border-radius: 50%;
    color: var(--color-background);
  }

  &:focus,
  &:focus-visible {
    span {
      background: var(--color-text);
    }
  }
}

.searchPopover {
  overflow: auto;
  min-width: 360px;
  max-width: 500px;
  max-height: calc(100vh - 42px - 24px - 100px);
  max-height: calc(100svh - 42px - 24px - 100px);
  padding: 10px 6px;
  background: var(--color-panel-background);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 0 10px var(--color-shadow);
  z-index: 999;
}

.searchLoading,
.searchNoResults {
  padding: 6px 10px;
  font-size: 13px;
  color: var(--color-opacity-05);
}

.searchEntry {
  display: flex;
  align-items: center;
  padding: 6px 14px;
  margin-bottom: 1px;
  border-radius: 6px;

  @include focus-self-outline;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    background: var(--color-opacity-005);
  }
}

.searchTypeIcon {
  display: block;
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  margin-right: 16px;
  opacity: var(--opacity-05);
}

.searchThumb {
  overflow: hidden;
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  margin-right: 16px;
  background: var(--color-opacity-01);
  background-image: $thumbnail;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.searchTitle {
  font-size: 13px;
}

.searchType {
  font-size: 11px;
  text-transform: capitalize;
  color: var(--color-opacity-05);
}

//
// SECTION HEADINGS
//

.label {
  display: block;
  position: relative;
  width: 100%;
  padding: 8px;
  margin-top: -4px;
  background: transparent;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 700;
  text-align: left;
  color: var(--color-opacity-05);

  @include focus-self-outline;

  // below user menu
  .userMenu + & {
    margin-top: 18px;
  }

  // below search bar
  .search + & {
    margin-top: 18px;
  }

  // below a link
  .link + & {
    margin-top: 10px;
  }

  // below another label
  .label + & {
    margin-top: 6px;
  }
}

.labelIcon {
  position: absolute;
  top: 50%;
  right: 0;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  opacity: 0;
  color: var(--color-text);

  .label:hover & {
    opacity: var(--opacity-05);
  }

  @include focus-child-custom() {
    opacity: var(--opacity-05);
  }
}

//
// MENU LINKS
//

.link {
  display: block;
  overflow: hidden;
  padding: 6px 11px;
  margin: 0 0 1px;
  border-radius: 6px;
  font-size: 13px;

  // prevent text wrapping and display an ellipsis
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 1024px) {
    padding: 6px 13px;
  }

  @include focus-self-outline;

  &:hover,
  &:focus {
    background: var(--color-opacity-005);
  }

  &Active {
    background: var(--color-opacity-0075) !important;
    font-weight: 600;
    color: var(--color-primary);
  }
}

.icon {
  display: inline-block;
  position: relative;
  top: 1px;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 0;
  opacity: var(--opacity-05);

  .linkActive & {
    opacity: 1;
  }

  // &Plus1 {
  //   top: 2px;
  // }
}
