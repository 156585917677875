@import '../../../css/global';

.wrap {
  display: flex;
  align-items: center;
  padding: 55px 10px 40px;

  :global(.cq-800) & {
    padding: 55px 10px 40px;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  &Padding {
    padding: 55px 30px 40px;

    :global(.cq-800) & {
      padding: 55px 40px 40px;
    }
  }
}

.thumb,
.thumbBg {
  overflow: hidden;
  flex: 0 0 auto;
  align-self: flex-start;
  width: 130px;
  height: 130px;
  margin-right: 20px;
  background: var(--color-opacity-01);
  border-radius: 6px;
  outline: 1px solid var(--color-opacity-005);

  :global(.cq-800) & {
    width: 160px;
    height: 160px;
    margin-right: 25px;
  }
  :global(.cq-920) & {
    width: 170px;
    height: 170px;
    margin-right: 30px;
  }
  :global(.cq-980) & {
    width: 180px;
    height: 180px;
    margin-right: 30px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.thumb {
  background-image: $thumbnail;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.thumbBg {
  :global(.cq-800) &,
  :global(.cq-920) &,
  :global(.cq-980) & {
    width: 160px;
    height: 160px;
    margin-right: 25px;
  }

  background: var(--color-primary);
  background: linear-gradient(to bottom, var(--color-primary) 0%, var(--color-background) 400%);
  outline: none;
  color: var(--color-background);
}

.thumbIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 56px;
  height: 56px;
  transform: translate(-50%, -50%);
}

.content {
  flex: 0 1 auto;
  width: 100%;
  padding-right: 50px;

  :global(.cq-800) & {
    padding-right: 150px;
  }
}

.title,
.subtitle,
.detail {
  a {
    border-radius: 3px;

    @include focus-self-outline;
  }
}

.title {
  width: 100%;
  margin-left: -1px;
  line-height: 1;
  font-weight: bold;

  &.xl {
    font-size: 38px;

    :global(.cq-680) & {
      font-size: 44px;
    }
    :global(.cq-800) & {
      margin-left: -2px;
      font-size: 48px;
    }
  }
  &.lg {
    font-size: 28px;

    :global(.cq-680) & {
      font-size: 32px;
    }
    :global(.cq-800) & {
      font-size: 36px;
    }
    :global(.cq-860) & {
      font-size: 42px;
    }
    :global(.cq-920) & {
      margin-left: -2px;
      font-size: 48px;
    }
  }
  &.md {
    font-size: 24px;

    :global(.cq-680) & {
      font-size: 30px;
    }
    :global(.cq-800) & {
      font-size: 34px;
    }
    :global(.cq-860) & {
      font-size: 38px;
    }
    :global(.cq-800) & {
      font-size: 42px;
    }
  }
  &.sm {
    font-size: 20px;

    :global(.cq-680) & {
      font-size: 24px;
    }
    :global(.cq-800) & {
      font-size: 28px;
    }
    :global(.cq-860) & {
      font-size: 32px;
    }
    :global(.cq-920) & {
      font-size: 36px;
    }
  }
}

.subtitle {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-opacity-06);

  :global(.cq-800) & {
    margin-top: 8px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.detail {
  margin-top: 4px;
  font-size: 12px;
  color: var(--color-opacity-06);

  :global(.cq-800) & {
    margin-top: 6px;
  }
}

//
// FILTERS
//

.filters {
  margin-top: 7px;
}

//
// BUTTONS
//

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 12px;

  :global(.cq-800) & {
    margin-top: 15px;
  }
}

.playButton,
.shuffleButton {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 2px 10px;
  background: var(--color-primary);
  border-radius: 6px;

  font-size: 12px;
  font-weight: bold;
  color: var(--color-background);

  :global(.cq-800) & {
    padding: 4px 12px;
  }

  @include focus-self-outline;
}

.playIcon,
.shuffleIcon {
  display: block;
  position: relative;
  width: 11px;
  height: 11px;
  margin: 2px 5px 2px 0;
}

.shuffleIcon {
  width: 15px;
  height: 15px;
  margin: 0 4px 0 -3px;
}

//
// STICKY
//

.stickyWrap {
  position: sticky;
  pointer-events: none;
  top: 0;
  opacity: 0;
  z-index: 2;

  &Visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.stickyContent {
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 91px;
  padding: 20px 10px;
  background: var(--color-background);

  :global(.cq-800) & {
    padding: 20px 10px;
  }

  .stickyWrapPadding & {
    padding: 20px 30px;

    :global(.cq-800) & {
      padding: 20px 40px;
    }
  }
}

.stickyTitle {
  overflow: hidden;
  padding-right: 70px;
  opacity: 0;
  // transition: opacity 0.3s;
  font-size: 26px;
  line-height: 1.2;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;

  :global(.cq-680) & {
    font-size: 28px;
  }
  :global(.cq-800) & {
    padding-right: 200px;
    font-size: 30px;
  }

  .stickyWrapVisible & {
    opacity: 1;
  }
}

.stickyTrigger {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
