$scrollbar-width: 6px;

html.electron.electron-platform-win {
  ::-webkit-scrollbar {
    width: $scrollbar-width; // For vertical scrollbar
    height: $scrollbar-width; // For horizontal scrollbar
    background: var(--color-opacity-01);
    visibility: hidden;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    visibility: hidden;
  }

  ::-webkit-scrollbar-thumb {
    width: $scrollbar-width;
    background: var(--color-opacity-015);
    border-radius: 0;
    visibility: hidden;
  }

  .layout-sidebar,
  .layout-content,
  .layout-controls,
  .layout-rightbar,
  .u-scrollbars,
  #scrollable {
    &:hover {
      &::-webkit-scrollbar {
        visibility: visible;
      }

      &::-webkit-scrollbar-track {
        visibility: visible;
      }

      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}
