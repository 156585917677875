@import '../../../css/global';

.wrap {
  display: grid;
  align-items: center;
  // grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr) auto 1fr;
  height: 100px;
  padding: 9px 20px 10px;
  background: var(--color-panel-background);
  border-top: 1px solid var(--color-border);
  text-align: center;

  @media (min-width: 1024px) {
    padding: 9px 26px 10px;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

// CURRENTLY PLAYING

.current {
  display: flex;
  align-items: center;
  text-align: left;
}

.cover,
.coverArtwork,
.coverLink {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 6px;

  @media (min-width: 880px) {
    width: 56px;
    height: 56px;
    margin-right: 12px;
  }
  @media (min-width: 1024px) {
    width: 68px;
    height: 68px;
    margin-right: 15px;
  }
}

.cover {
  background: var(--color-opacity-01);
  background-image: $thumbnail;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  outline: 1px solid var(--color-opacity-01);

  &Placeholder {
    outline: 1px solid var(--color-opacity-015);
  }
}

.coverArtwork,
.coverLink {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include focus-self-outline;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.coverLink {
  &:hover {
    outline: 1px solid var(--color-opacity-02);
  }
}

.text {
  width: calc(100% - 60px);

  @media (min-width: 1024px) {
    width: calc(100% - 75px);
  }
}

.title {
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 1024px) {
    font-size: 13px;
  }
}

.artist {
  display: block;
  overflow: hidden;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-opacity-07);

  @media (min-width: 1024px) {
    font-size: 12px;
  }

  a {
    @include focus-self-underline;
  }
}

// PRIMARY CONTROLS

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 40vw;
  min-width: 320px;
  max-width: 566px;

  @media (min-width: 1024px) {
    gap: 10px;
  }
}

button.shuffle,
button.rewind,
button.play,
button.pause,
button.forward,
button.repeat,
button.queue,
button.volume,
.offline {
  display: block;
  position: relative;
  width: 44px;
  height: 44px;
  padding: 8px;
  // margin: 0 5px;
  background: none;
  border: none;
  border: 2px solid var(--color-panel-background);
  border-radius: 50%;
  cursor: pointer;

  @include focus-self-outline;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:not(:disabled):hover {
    background: var(--color-opacity-005);
  }
}

button.rewind {
  padding: 8px 9px 8px 7px;
}

button.forward {
  padding: 8px 7px 8px 9px;
}

button.shuffle,
button.repeat {
  padding: 9px;
}

button.play,
button.pause {
  margin: 0 6px;
  background: var(--color-primary) !important;
  border: 2px solid var(--color-panel-background);
  color: var(--color-panel-background);

  &:disabled {
    background: var(--color-text) !important;
    opacity: 0.3;
  }
}

button.play {
  padding: 10px 8px 10px 12px;
}

button.pause {
  padding: 11px;
}

button.volume {
  margin-right: 2px;
}

button.queue {
  padding: 11px;
  margin-right: 0;
}

button.active {
  color: var(--color-primary);
}

.offline {
  width: 36px;
  height: 36px;
  padding: 6px;
  background: var(--color-text) !important;
  color: var(--color-panel-background) !important;
  cursor: default;
}

.loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(../../../images/loading-black.gif);
  background-image: url(../../../images/loading-black.png);
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

// SCRUBBER

.scrubber {
  display: flex;
  align-items: center;
  margin: 4px auto -6px;
}

.scrubSlider {
  width: 100%;
}

.scrubLeft,
.scrubRight {
  min-width: 35px;
  padding-bottom: 1px;
  font-size: 10px;
  line-height: 1;
  color: var(--color-opacity-07);
}
.scrubLeft {
  margin-right: 8px;
  text-align: right;
}
.scrubRight {
  margin-left: 8px;
  text-align: left;
}

// SECONDARY CONTROLS

.secondary {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}

.secondaryControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  // @media (min-width: 1024px) {
  //   gap: 10px;
  // }

  .volSlider + & {
    margin-left: 16px;
  }
}

.volSlider {
  width: 100px;

  @media (min-width: 1024px) {
    width: 120px;
  }
}
