.electron-drag {
  display: none;

  html.electron & {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 20px;
    height: 40px;

    // background: rgba(255, 0, 0, 0.2);
    // z-index: 999999;

    @include electron-drag;
  }

  html.electron.electron-platform-mac & {
    left: 80px;
  }

  html.electron.electron-platform-win & {
    right: 140px;
  }
}
