@import '../../../css/global';

.wrap {
  max-width: 800px;
  padding: 0 30px 50px;

  :global(.cq-800) & {
    padding: 0 40px 50px;
  }
}

.group {
  font-size: 14px;

  + .group {
    margin-top: 30px;
  }
}

.title {
  margin: 0 0 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-opacity-05);
}

.menu {
}

.menuEntry {
  @include focus-parent-outline;

  + .menuEntry {
    // margin-top: 4px;
    margin-top: 14px;
  }

  label {
    display: inline-flex;
    align-items: center;
    margin: 0 auto 0 0;
  }

  input[type='checkbox'] {
    margin: 0 10px 0 0;
  }
}

.label {
}

.description {
  display: block;
  font-size: 12px;
  color: var(--color-opacity-07);
}
