// ======================================================================
// FONTS
// ======================================================================

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  font-size: 16px;
  line-height: 1.4;
  font-weight: normal;
  color: $color-text;
}

html.logged-in,
html.logged-in body {
  min-width: 720px;
  color: var(--color-text);
  overscroll-behavior: none;
}

html {
  font-family: 'GeographWeb', -apple-system, 'SF Pro', 'Inter', Helvetica, Arial, sans-serif;
  font-size: 62.5%;

  &.logged-in {
    font-family: -apple-system, 'SF Pro', 'Inter', Helvetica, Arial, sans-serif;
  }

  // &[data-font-size='larger'] {
  //   font-size: 77.5%;
  // }
  // &[data-font-size='large'] {
  //   font-size: 72.5%;
  // }
  // &[data-font-size='medium'] {
  //   font-size: 67.5%;
  // }
  // &[data-font-size='smaller'] {
  //   font-size: 57.5%;
  // }
}

a {
  font: inherit;
  text-decoration: inherit;
  color: inherit;
}

sup {
  vertical-align: top;
  font-size: 0.8em;
}

.text-center {
  text-align: center;
}

.text-trim {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-hide-text {
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}

.font-markdown,
.font-wysiwyg {
  font-size: 15px;

  // pre {
  //   font: inherit;
  // }
  // pre + pre {
  //   padding-top: 30px;
  //   margin-top: 30px;
  //   border-top: 1px solid var(--color-border-page-divider);
  // }

  div + div {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid var(--color-opacity-015);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.1em;
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  ul,
  ol {
    margin-left: 20px;
  }

  i,
  em {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  a {
    border-radius: 2px;
    text-decoration: underline;
    color: var(--color-primary);

    @include focus-self-outline();
  }
}

.font-wysiwyg {
  max-width: 800px;
  font-size: 16px;
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin-top: 1em;
    margin-bottom: 1em;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
